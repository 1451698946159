// src/Favorites.jsx
import React, { useState, useEffect } from 'react';
import { getUserFavorites, removeFromFavorites } from './userFavorites';
import { FaStar, FaPlus, FaTrash } from 'react-icons/fa6';

const Favorites = ({ userEmail, onAddItem, onNotify }) => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFavorites = async () => {
      if (userEmail) {
        setLoading(true);
        try {
          const userFavorites = await getUserFavorites(userEmail);
          setFavorites(userFavorites);
        } catch (error) {
          console.error("Error fetching favorites:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchFavorites();
  }, [userEmail]);

  const handleAddToList = (favorite) => {
    onAddItem({
      name: favorite.itemName,
      quantity: favorite.quantity || 1,
      notes: favorite.notes || ""
    });
    onNotify("added a favorite item to the shopping list");
  };

  const handleRemoveFavorite = async (favoriteId) => {
    try {
      await removeFromFavorites(favoriteId);
      setFavorites(favorites.filter(fav => fav.id !== favoriteId));
      onNotify("removed an item from favorites");
    } catch (error) {
      console.error("Error removing favorite:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <svg className="animate-spin h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    );
  }

  return (
    <div className="mb-6">
      <h2 className="text-xl font-semibold text-gray-700 mb-4 flex items-center">
        <FaStar className="text-yellow-500 mr-2" /> My Favorites
      </h2>
      
      {favorites.length > 0 ? (
        <div className="flex flex-wrap gap-2">
          {favorites.map(favorite => (
            <div 
              key={favorite.id} 
              className="group flex items-center px-3 py-2 bg-yellow-50 text-yellow-800 border border-yellow-200 rounded-md hover:bg-yellow-100 transition-colors"
            >
              <button 
                className="mr-2 text-green-600 hover:text-green-800"
                onClick={() => handleAddToList(favorite)}
              >
                <FaPlus className="text-xs" />
              </button>
              
              <span>{favorite.itemName}</span>
              
              {favorite.notes && (
                <span className="ml-1 text-xs text-gray-500">({favorite.notes})</span>
              )}
              
              <button 
                className="ml-2 text-red-500 hover:text-red-700"
                onClick={() => handleRemoveFavorite(favorite.id)}
              >
                <FaTrash className="text-xs" />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500 text-sm">
          No favorite items yet. Click the star icon on any item to add it to your favorites.
        </p>
      )}
    </div>
  );
};

export default Favorites;
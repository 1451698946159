// src/userFavorites.js
import { getFirestore, collection, query, where, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';

export const getUserFavorites = async (userEmail) => {
  const firestore = getFirestore();
  const favoritesRef = collection(firestore, 'favorites');
  const q = query(favoritesRef, where("userEmail", "==", userEmail));

  try {
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching favorites:", error);
    return [];
  }
};

export const addToFavorites = async (userEmail, item) => {
  const firestore = getFirestore();
  const favoritesRef = collection(firestore, 'favorites');

  try {
    // Check if item already exists in favorites
    const q = query(
      favoritesRef, 
      where("userEmail", "==", userEmail),
      where("itemName", "==", item.itemName),
      where("notes", "==", item.notes || "")
    );
    
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      // Add to favorites if it doesn't exist
      const favoriteItem = {
        userEmail,
        itemName: item.itemName,
        quantity: item.quantity || 1,
        notes: item.notes || "",
        timestamp: new Date()
      };
      
      const docRef = await addDoc(favoritesRef, favoriteItem);
      return { id: docRef.id, ...favoriteItem };
    } else {
      // Item already exists in favorites
      return null;
    }
  } catch (error) {
    console.error("Error adding to favorites:", error);
    throw error;
  }
};

export const removeFromFavorites = async (favoriteId) => {
  const firestore = getFirestore();
  const favoriteRef = doc(firestore, 'favorites', favoriteId);
  
  try {
    await deleteDoc(favoriteRef);
    return true;
  } catch (error) {
    console.error("Error removing from favorites:", error);
    throw error;
  }
};
// src/userService.js
import { getFirestore, collection, getDocs } from 'firebase/firestore';

export const getAllUsers = async () => {
  const firestore = getFirestore();
  
  try {
    // We need to create a collection of all registered users
    // You should ensure this collection exists in your Firestore
    const usersCollection = collection(firestore, 'users');
    const snapshot = await getDocs(usersCollection);
    
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error("Error fetching users:", error);
    return [];
  }
};
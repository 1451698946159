// src/AdminPanel.jsx
import React, { useState, useEffect } from 'react';
import { getAdminsList, addAdmin, removeAdmin } from './adminService';
import { getAllUsers } from './userService';
import { FaUserShield, FaUserPlus, FaUserMinus, FaTimes, FaUsers, FaUserCheck } from 'react-icons/fa';

const AdminPanel = ({ userEmail, onNotify }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [users, setUsers] = useState([]);
  const [newAdminEmail, setNewAdminEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('admins'); // 'admins' or 'users'

  // Fetch admins and users list when panel opens
  useEffect(() => {
    if (isOpen) {
      fetchAdmins();
      fetchUsers();
    }
  }, [isOpen]);

  const fetchAdmins = async () => {
    setLoading(true);
    try {
      const adminsList = await getAdminsList();
      setAdmins(adminsList);
      setError('');
    } catch (err) {
      setError('Failed to load admins list');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    setUserLoading(true);
    try {
      const usersList = await getAllUsers();
      setUsers(usersList);
    } catch (err) {
      console.error("Error fetching users:", err);
    } finally {
      setUserLoading(false);
    }
  };

  const handleAddAdmin = async () => {
    if (!newAdminEmail || !newAdminEmail.includes('@')) {
      setError('Please enter a valid email address');
      return;
    }

    setLoading(true);
    try {
      const result = await addAdmin(userEmail, newAdminEmail);
      
      if (result.success) {
        onNotify(`added ${newAdminEmail} as an admin`);
        setNewAdminEmail('');
        await fetchAdmins();
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError(err.message || 'Failed to add admin');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveAdmin = async (adminEmail) => {
    setLoading(true);
    try {
      const result = await removeAdmin(userEmail, adminEmail);
      
      if (result.success) {
        onNotify(`removed ${adminEmail} from admins`);
        await fetchAdmins();
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError(err.message || 'Failed to remove admin');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePromoteToAdmin = (email) => {
    setNewAdminEmail(email);
    setActiveTab('admins');
  };

  const isUserAdmin = (email) => {
    return admins.includes(email);
  };

  // Skip rendering the full panel if it's not open
  if (!isOpen) {
    return (
      <button
        className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 flex items-center"
        onClick={() => setIsOpen(true)}
        title="Admin Panel"
      >
        <FaUserShield className="mr-2" />
        Admin
      </button>
    );
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6 max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-gray-800 flex items-center">
            <FaUserShield className="text-purple-600 mr-2" /> Admin Panel
          </h2>
          <button 
            className="text-gray-500 hover:text-gray-700"
            onClick={() => setIsOpen(false)}
          >
            <FaTimes size={20} />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md text-sm">
            {error}
          </div>
        )}

        {/* Tabs */}
        <div className="border-b border-gray-200 mb-6">
          <div className="flex">
            <button
              onClick={() => setActiveTab('admins')}
              className={`px-4 py-2 text-sm font-medium ${
                activeTab === 'admins'
                  ? 'border-b-2 border-purple-500 text-purple-600'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              <FaUserShield className="inline mr-2" /> Admins
            </button>
            <button
              onClick={() => setActiveTab('users')}
              className={`px-4 py-2 text-sm font-medium ${
                activeTab === 'users'
                  ? 'border-b-2 border-purple-500 text-purple-600'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              <FaUsers className="inline mr-2" /> All Users
            </button>
          </div>
        </div>

        {activeTab === 'admins' && (
          <>
            <div className="mb-6">
              <h3 className="text-lg font-semibold text-gray-700 mb-3">Current Admins</h3>
              {loading ? (
                <div className="flex justify-center py-4">
                  <svg className="animate-spin h-5 w-5 text-purple-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              ) : (
                <ul className="space-y-2">
                  {admins.map(admin => (
                    <li 
                      key={admin} 
                      className="flex items-center justify-between p-3 bg-gray-50 rounded-md"
                    >
                      <span className="text-gray-800">{admin}</span>
                      {admin !== 'hadeedulhaq@gmail.com' && (
                        <button
                          className="p-1 text-red-500 hover:text-red-700"
                          onClick={() => handleRemoveAdmin(admin)}
                          disabled={loading}
                        >
                          <FaUserMinus size={16} />
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div>
              <h3 className="text-lg font-semibold text-gray-700 mb-3">Add New Admin</h3>
              <div className="flex space-x-2">
                <input
                  type="email"
                  className="flex-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                  placeholder="user@example.com"
                  value={newAdminEmail}
                  onChange={(e) => setNewAdminEmail(e.target.value)}
                  disabled={loading}
                />
                <button
                  className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500 disabled:opacity-50"
                  onClick={handleAddAdmin}
                  disabled={loading || !newAdminEmail}
                >
                  <FaUserPlus className="mr-2" />
                  Add
                </button>
              </div>
            </div>
          </>
        )}

        {activeTab === 'users' && (
          <div>
            <h3 className="text-lg font-semibold text-gray-700 mb-3">Registered Users</h3>
            {userLoading ? (
              <div className="flex justify-center py-4">
                <svg className="animate-spin h-5 w-5 text-purple-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            ) : (
              <>
                {users.length === 0 ? (
                  <p className="text-center text-gray-500 py-4">No registered users found.</p>
                ) : (
                  <ul className="space-y-2">
                    {users.map(user => (
                      <li 
                        key={user.id} 
                        className="flex items-center justify-between p-3 bg-gray-50 rounded-md"
                      >
                        <div>
                          <span className="text-gray-800">{user.email}</span>
                          {isUserAdmin(user.email) && (
                            <span className="ml-2 px-2 py-0.5 bg-purple-100 text-purple-700 text-xs rounded-full">
                              Admin
                            </span>
                          )}
                          <div className="text-xs text-gray-500">
                            Last login: {user.lastLogin ? new Date(user.lastLogin).toLocaleString() : 'Never'}
                          </div>
                        </div>
                        
                        {!isUserAdmin(user.email) && (
                          <button
                            className="p-1 text-purple-500 hover:text-purple-700"
                            onClick={() => handlePromoteToAdmin(user.email)}
                            title="Make Admin"
                          >
                            <FaUserCheck size={16} />
                          </button>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPanel;
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import { getSuggestedItems } from './suggestedItems';
import './App.css';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, onSnapshot, deleteDoc, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { FaPlus, FaPen, FaTrash, FaCheck, FaXmark, FaStar } from 'react-icons/fa6';
import { FaUnlock, FaLock } from "react-icons/fa";
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import Notification from './Notification';
import Favorites from './Favorites';
import { addToFavorites } from './userFavorites';
import AdminPanel from './AdminPanel';
import { checkIsAdmin } from './adminService';
import { addGlobalNotification, subscribeToNotifications } from './notificationService';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDxEG6huOR_evMmfyhXEuuaAtVztiHQXP4",
  authDomain: "home-shopping-list-9a89d.firebaseapp.com",
  projectId: "home-shopping-list-9a89d",
  storageBucket: "home-shopping-list-9a89d.appspot.com",
  messagingSenderId: "689872741679",
  appId: "1:689872741679:web:27c4053e3b1419a5846dda",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth();

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [notes, setNotes] = useState('');
  const [items, setItems] = useState([]);
  const [editedItemName, setEditedItemName] = useState('');
  const [editedQuantity, setEditedQuantity] = useState('');
  const [editedNotes, setEditedNotes] = useState('');
  const [editItemId, setEditItemId] = useState(null);
  const [suggestedItems, setSuggestedItems] = useState([]);
  const [notification, setNotification] = useState(null);
  const [notificationUser, setNotificationUser] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);


  useEffect(() => {
    const checkAdminStatus = async () => {
      if (user && user.email) {
        try {
          const adminStatus = await checkIsAdmin(user.email);
          setIsAdmin(adminStatus);
        } catch (error) {
          console.error("Error checking admin status:", error);
          setIsAdmin(false);
        }
      }
    };
  
    checkAdminStatus();
  }, [user]);
  useEffect(() => {
    if (user) {
      // Subscribe to global notifications
      const unsubscribeFromNotifications = subscribeToNotifications((notification) => {
        // Don't show notifications for actions performed by the current user
        if (notification.actionUser !== getNameFromEmail(user.email)) {
          setNotification(notification.message);
          setNotificationUser(notification.actionUser);
        }
      });
      
      // Clean up subscription when component unmounts
      return () => {
        if (unsubscribeFromNotifications) {
          unsubscribeFromNotifications();
        }
      };
    }
  }, [user]);

  // Fetch items from Firestore
  useEffect(() => {
    const fetchItems = async () => {
      const itemsCollection = collection(firestore, 'items');
      const unsubscribe = onSnapshot(itemsCollection, (snapshot) => {
        const itemsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        // Sort items by timestamp in descending order (newest first)
        const sortedItems = itemsData.sort((a, b) => {
          // Handle items without timestamp
          if (!a.timestamp) return 1;
          if (!b.timestamp) return -1;
          return b.timestamp.seconds - a.timestamp.seconds;
        });
        setItems(sortedItems);
      });
      return unsubscribe;
    };
    fetchItems();
  }, []);

  // Fetch suggested items based on the logged-in user's email
  useEffect(() => {
    if (user && user.email) {
      const userEmail = user.email;
      const currentUserSuggestedItems = getSuggestedItems(userEmail);
      setSuggestedItems(currentUserSuggestedItems);
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="p-4 bg-white rounded-lg shadow-md">
          <div className="flex items-center space-x-3">
            <svg className="w-8 h-8 text-blue-600 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span className="text-lg font-medium text-gray-700">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
  
  const handleLogout = async () => {
    try {
      await signOut(auth);
      // No need to redirect as your Routes setup will handle that
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  // Calculate number of bought items
  const numBoughtItems = items.filter(item => item.isBought).length;
  const numTotalItems = items.length;

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';

    // Convert timestamp to Date object
    const date = timestamp.toDate();

    // Format date and time
    const formattedDate = date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    const formattedTime = date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit'
    });

    return `${formattedDate} at ${formattedTime}`;
  };

  const showNotification = (message, user = null) => {
    const displayUser = user || (user?.email ? getNameFromEmail(user.email) : '');
    setNotification(message);
    setNotificationUser(displayUser);
    
    // Also add this as a global notification
    addGlobalNotification(message, displayUser);
  };

  const closeNotification = () => {
    setNotification(null);
    setNotificationUser('');
  };

  const handleAddItem = async (item) => {
    try {
      // Check if item name and quantity are not empty
      if (!item.name || !item.quantity) {
        alert('Please enter both item name and quantity.');
        return;
      }
  
      // Find existing item with the same name and notes
      const existingItemIndex = items.findIndex(existingItem => existingItem.itemName === item.name && existingItem.notes === item.notes);
  
      if (existingItemIndex !== -1) {
        // If the item already exists with the same name and notes, update its quantity
        const existingItem = items[existingItemIndex];
        const newQuantity = existingItem.quantity + parseInt(item.quantity, 10); // Parse quantity as an integer
  
        const itemDocRef = doc(firestore, 'items', existingItem.id);
        await updateDoc(itemDocRef, { 
          quantity: newQuantity,
          timestamp: serverTimestamp(),
          lastUpdatedBy: user.email
        });
        
        showNotification(`updated quantity of ${item.name}`, getNameFromEmail(user.email));
      } else {
        // Add new item with user's display name
        const timestamp = serverTimestamp();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (user) {
            console.log("Current User:", user);
            const { email } = user;
            await addDoc(collection(firestore, 'items'), {
              itemName: item.name,
              quantity: parseInt(item.quantity, 10),
              notes: item.notes,
              addedBy: email, // Use user's email
              timestamp: timestamp,
              lastUpdatedBy: email
            });
            
            showNotification(`added ${item.name} to the shopping list`, getNameFromEmail(user.email));
            
            // Clear input fields after adding item
            setItemName('');
            setQuantity('');
            setNotes('');
          } else {
            console.error('User not logged in');
          }
  
          // Unsubscribe from onAuthStateChanged listener to avoid memory leaks
          unsubscribe();
        });
      }
    } catch (error) {
      console.error("Error adding item: ", error);
    }
  };
  
const handleDeleteItem = async (itemId) => {
  try {
    // Get the item from the items array
    const itemToDelete = items.find(item => item.id === itemId);

    // Check if the item exists
    if (!itemToDelete) {
      console.error("Item not found.");
      return;
    }

    // Check if the item belongs to the current user or if the user is an admin
    if (itemToDelete.addedBy !== user.email && !isAdmin) {
      // Show an error message to the user
      alert('You can only delete your own items unless you are an admin.');
      return;
    }

    // Update timestamp
    const timestamp = serverTimestamp();
    const itemDocRef = doc(firestore, 'items', itemId);
    await updateDoc(itemDocRef, { 
      timestamp: timestamp,
      lastUpdatedBy: user.email 
    });
    
    await deleteDoc(itemDocRef);
    
    showNotification(`deleted ${itemToDelete.itemName} from the shopping list`, getNameFromEmail(user.email));
  } catch (error) {
    console.error("Error deleting item: ", error);
  }
};
  
const handleUpdateItem = async (itemId, newItemName, newQuantity, newNotes) => {
  try {
    // Check if new quantity is not empty
    if (!newQuantity) {
      alert('Please enter the new quantity.');
      return;
    }

    // Get the item from the items array
    const itemToUpdate = items.find(item => item.id === itemId);

    // Check if the item exists
    if (!itemToUpdate) {
      console.error("Item not found.");
      return;
    }

    // Check if the item belongs to the current user or if the user is an admin
    if (itemToUpdate.addedBy !== user.email && !isAdmin) {
      // Show an error message to the user
      alert('You can only update items added by you unless you are an admin.');
      return;
    }

    // Update timestamp
    const timestamp = serverTimestamp();
    const itemDocRef = doc(firestore, 'items', itemId);
    await updateDoc(itemDocRef, {
      itemName: newItemName,
      quantity: parseInt(newQuantity, 10),
      notes: newNotes,
      timestamp: timestamp,
      lastUpdatedBy: user.email
    });
    
    showNotification(`updated ${itemToUpdate.itemName}`, getNameFromEmail(user.email));

    // Reset edit state
    setEditItemId(null);
    setEditedItemName('');
    setEditedQuantity('');
    setEditedNotes('');
  } catch (error) {
    console.error("Error updating item: ", error);
  }
};
  
  const getNameFromEmail = (email) => {
    if (!email) return '';
    const atIndex = email.indexOf('@');
    if (atIndex !== -1) {
      return email.substring(0, atIndex);
    } else {
      return email;
    }
  };

  const handleMarkAsBought = async (itemId, isBought, itemObject) => {
    try {
      const itemDocRef = doc(firestore, 'items', itemId);
      
      // If a different user is trying to mark as bought/unbought
      if (itemObject.addedBy !== user.email) {
        // If the item is already bought and this is a different user, revert to unbought
        if (isBought) {
          await updateDoc(itemDocRef, {
            isBought: false,
            timestamp: serverTimestamp(),
            lastUpdatedBy: user.email
          });
          
          showNotification(`marked ${itemObject.itemName} as not purchased`, getNameFromEmail(user.email));
          return;
        }
      }
      
      // Toggle isBought status
      await updateDoc(itemDocRef, {
        isBought: !isBought,
        timestamp: serverTimestamp(),
        lastUpdatedBy: user.email
      });
      
      showNotification(
        `marked ${itemObject.itemName} as ${!isBought ? 'purchased' : 'not purchased'}`, 
        getNameFromEmail(user.email)
      );
    } catch (error) {
      console.error("Error marking item as bought: ", error);
    }
  };
  
  const handleAddToFavorites = async (item) => {
    try {
      const result = await addToFavorites(user.email, item);
      if (result) {
        showNotification(`added ${item.itemName} to favorites`, getNameFromEmail(user.email));
      } else {
        showNotification(`${item.itemName} is already in your favorites`, getNameFromEmail(user.email));
      }
    } catch (error) {
      console.error("Error adding to favorites:", error);
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={user ? <Navigate to="/app" /> : <Navigate to="/login" />} />
        <Route path="/login" element={user ? <Navigate to="/app" /> : <Login />} />
        <Route path="/app" element={user ? (
          <div className="min-h-screen bg-gray-50">
            <div className="container mx-auto px-4 py-8 max-w-4xl">
              {/* Header with user info and logout button */}
              <div className="flex justify-between items-center mb-6 bg-white rounded-lg shadow-md p-4">
  <div className="flex items-center">
    <div className="h-10 w-10 rounded-full bg-blue-500 flex items-center justify-center">
      <span className="text-white font-bold">{user.email ? user.email.charAt(0).toUpperCase() : ''}</span>
    </div>
    <div className="ml-3">
      <p className="text-sm font-medium text-gray-700">Logged in as</p>
      <p className="text-sm text-gray-500">{user.email}</p>
    </div>
  </div>
  <div className="flex space-x-3">
    {isAdmin && (
      <AdminPanel 
        userEmail={user.email}
        onNotify={(message) => showNotification(message, getNameFromEmail(user.email))}
      />
    )}
    <button 
      onClick={handleLogout}
      className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
    >
      Log Out
    </button>
  </div>
</div>
              
              <div className="mb-8 bg-white rounded-lg shadow-md p-6">
                <h1 className="text-2xl font-bold text-gray-800 mb-4">Add New Item</h1>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <input 
                    type="text" 
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                    placeholder="Item Name" 
                    value={itemName} 
                    onChange={(e) => setItemName(e.target.value)} 
                  />
                  <input 
                    type="number" 
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                    placeholder="Quantity" 
                    value={quantity} 
                    onChange={(e) => setQuantity(e.target.value)} 
                  />
                  <input 
                    type="text" 
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                    placeholder="Notes" 
                    value={notes} 
                    onChange={(e) => setNotes(e.target.value)} 
                  />
                </div>
                <div className="mt-4 flex justify-end">
                  <button 
                    className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => handleAddItem({ name: itemName, quantity: quantity, notes: notes })}
                  >
                    <FaPlus className="mr-2" />
                    Add Item
                  </button>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-md p-6 mb-8">
                <div className="flex items-center justify-between mb-4">
                  <h1 className="text-2xl font-bold text-gray-800">Shopping List</h1>
                  <div className={`px-3 py-1 rounded-full text-white text-sm font-semibold ${numBoughtItems === numTotalItems && numTotalItems > 0 ? 'bg-green-500' : 'bg-blue-500'}`}>
                    {numBoughtItems}/{numTotalItems}
                  </div>
                </div>
                
                <div className="text-sm text-gray-500 mb-6">
                  Last Updated: {formatTimestamp(items.length > 0 ? items[0].timestamp : null)}
                  {items.length > 0 && items[0].lastUpdatedBy && (
                    <span className="ml-1">by {getNameFromEmail(items[0].lastUpdatedBy)}</span>
                  )}
                </div>
                
                {/* User's Favorites */}
                <Favorites 
                  userEmail={user.email} 
                  onAddItem={handleAddItem} 
                  onNotify={(message) => showNotification(message, getNameFromEmail(user.email))}
                />

                <h2 className="text-xl font-semibold text-gray-700 mb-4">Suggested Items</h2>
                <div className="flex flex-wrap gap-2 mb-6">
                  {suggestedItems.map(item => (
                    <button 
                      key={item.id} 
                      className="flex items-center px-3 py-2 bg-blue-50 text-blue-700 border border-blue-200 rounded-md hover:bg-blue-100 transition-colors"
                      onClick={() => handleAddItem(item)}
                    >
                      <FaPlus className="mr-2 text-xs" /> {item.name}
                    </button>
                  ))}
                </div>

                {items.length > 0 ? (
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item Name</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Notes</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Added By</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {items.map(item => (
                          <tr key={item.id} className={item.isBought ? "bg-green-50" : ""}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {editItemId === item.id ? (
                                <input
                                  type="text"
                                  className="px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                                  value={editedItemName}
                                  onChange={(e) => setEditedItemName(e.target.value)}
                                />
                              ) : (
                                <span className={`text-sm ${item.isBought ? 'text-gray-500 line-through' : 'text-gray-900'}`}>{item.itemName}</span>
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {editItemId === item.id ? (
                                <input
                                  type="number"
                                  className="px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                                  value={editedQuantity}
                                  onChange={(e) => setEditedQuantity(e.target.value)}
                                />
                              ) : (
                                <span className={`text-sm ${item.isBought ? 'text-gray-500 line-through' : 'text-gray-900'}`}>{item.quantity}</span>
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {editItemId === item.id ? (
                                <input
                                  type="text"
                                  className="px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                                  value={editedNotes}
                                  onChange={(e) => setEditedNotes(e.target.value)}
                                />
                              ) : (
                                <span className={`text-sm ${item.isBought ? 'text-gray-500 line-through' : 'text-gray-900'}`}>{item.notes}</span>
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className="text-sm text-gray-700">{getNameFromEmail(item.addedBy)}</span>
                              {item.lastUpdatedBy && item.lastUpdatedBy !== item.addedBy && (
                                <span className="text-xs text-gray-500 ml-1 block">
                                  Last updated by: {getNameFromEmail(item.lastUpdatedBy)}
                                </span>
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {editItemId === item.id ? (
                                <div className="flex space-x-2">
                                  <button 
                                    className="p-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                                    onClick={() => handleUpdateItem(item.id, editedItemName, editedQuantity, editedNotes)}
                                  >
                                    <FaCheck className="text-xs" />
                                  </button>
                                  <button 
                                    className="p-2 bg-gray-400 text-white rounded hover:bg-gray-500 transition-colors"
                                    onClick={() => setEditItemId(null)}
                                  >
                                    <FaXmark className="text-xs" />
                                  </button>
                                </div>
                              ) : (
                                <div className="flex space-x-2">
                                  <button 
                                    className="p-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors"
                                    onClick={() => { 
                                      setEditedItemName(item.itemName); 
                                      setEditedQuantity(item.quantity); 
                                      setEditedNotes(item.notes); 
                                      setEditItemId(item.id); 
                                    }}
                                    title="Edit"
                                  >
                                    <FaPen className="text-xs" />
                                  </button>
                                  <button 
                                    className="p-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                                    onClick={() => handleDeleteItem(item.id)}
                                    title="Delete"
                                  >
                                    <FaTrash className="text-xs" />
                                  </button>
                                  <button 
                                    className={`p-2 ${item.isBought ? 'bg-gray-500' : 'bg-green-500'} text-white rounded hover:${item.isBought ? 'bg-gray-600' : 'bg-green-600'} transition-colors`}
                                    onClick={() => handleMarkAsBought(item.id, item.isBought, item)}
                                    title={item.isBought ? "Mark as not purchased" : "Mark as purchased"}
                                  >
                                    {item.isBought ? <FaUnlock className="text-xs" /> : <FaLock className="text-xs" />}
                                  </button>
                                  <button 
                                    className="p-2 bg-yellow-400 text-white rounded hover:bg-yellow-500 transition-colors"
                                    onClick={() => handleAddToFavorites(item)}
                                    title="Add to favorites"
                                  >
                                    <FaStar className="text-xs" />
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="text-center py-10">
                    <p className="text-gray-500">No items in your shopping list yet.</p>
                    <p className="text-gray-500 mt-2">Add items or select from suggested items above.</p>
                  </div>
                )}
              </div>
            </div>
            
            {/* Notification component */}
            {notification && (
              <Notification 
                message={notification}
                actionUser={notificationUser}
                onClose={closeNotification}
              />
            )}
          </div>
        ) : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
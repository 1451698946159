// src/adminService.js
import { getFirestore, doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';

// Default admin email
const DEFAULT_ADMIN = 'hadeedulhaq@gmail.com';

// Check if a user is an admin
export const checkIsAdmin = async (userEmail) => {
  // Default admin always has privileges
  if (userEmail === DEFAULT_ADMIN) {
    return true;
  }

  const firestore = getFirestore();
  
  try {
    // Check if admins collection exists
    const adminsDoc = doc(firestore, 'system', 'admins');
    const docSnap = await getDoc(adminsDoc);
    
    if (docSnap.exists()) {
      const adminsData = docSnap.data();
      // Check if the user is in the admins list
      return adminsData.adminList && adminsData.adminList.includes(userEmail);
    }
    
    // If admin document doesn't exist, create it with default admin
    await setDoc(adminsDoc, {
      adminList: [DEFAULT_ADMIN]
    });
    
    return userEmail === DEFAULT_ADMIN;
  } catch (error) {
    console.error("Error checking admin status:", error);
    // Default to user not being admin on error
    return userEmail === DEFAULT_ADMIN;
  }
};

// Get all admins
export const getAdminsList = async () => {
  const firestore = getFirestore();
  
  try {
    const adminsDoc = doc(firestore, 'system', 'admins');
    const docSnap = await getDoc(adminsDoc);
    
    if (docSnap.exists()) {
      const adminsData = docSnap.data();
      return adminsData.adminList || [DEFAULT_ADMIN];
    }
    
    // If admin document doesn't exist, create it with default admin
    await setDoc(adminsDoc, {
      adminList: [DEFAULT_ADMIN]
    });
    
    return [DEFAULT_ADMIN];
  } catch (error) {
    console.error("Error getting admins list:", error);
    return [DEFAULT_ADMIN];
  }
};

// Add a new admin
export const addAdmin = async (currentUserEmail, newAdminEmail) => {
  // Validate if the current user is an admin
  const isAdmin = await checkIsAdmin(currentUserEmail);
  
  if (!isAdmin) {
    throw new Error('Only admins can add other admins');
  }
  
  const firestore = getFirestore();
  const adminsDoc = doc(firestore, 'system', 'admins');
  
  try {
    const docSnap = await getDoc(adminsDoc);
    
    if (docSnap.exists()) {
      const adminsData = docSnap.data();
      const currentAdmins = adminsData.adminList || [DEFAULT_ADMIN];
      
      // Check if user is already an admin
      if (currentAdmins.includes(newAdminEmail)) {
        return { success: false, message: 'User is already an admin' };
      }
      
      // Add the new admin
      const updatedAdmins = [...currentAdmins, newAdminEmail];
      await updateDoc(adminsDoc, { adminList: updatedAdmins });
      
      return { success: true, message: 'Admin added successfully' };
    } else {
      // Create the admins document if it doesn't exist
      await setDoc(adminsDoc, {
        adminList: [DEFAULT_ADMIN, newAdminEmail]
      });
      
      return { success: true, message: 'Admin added successfully' };
    }
  } catch (error) {
    console.error("Error adding admin:", error);
    throw error;
  }
};

// Remove an admin (cannot remove default admin)
export const removeAdmin = async (currentUserEmail, adminToRemoveEmail) => {
  // Cannot remove the default admin
  if (adminToRemoveEmail === DEFAULT_ADMIN) {
    throw new Error('Cannot remove the default admin');
  }
  
  // Validate if the current user is an admin
  const isAdmin = await checkIsAdmin(currentUserEmail);
  
  if (!isAdmin) {
    throw new Error('Only admins can remove other admins');
  }
  
  const firestore = getFirestore();
  const adminsDoc = doc(firestore, 'system', 'admins');
  
  try {
    const docSnap = await getDoc(adminsDoc);
    
    if (docSnap.exists()) {
      const adminsData = docSnap.data();
      const currentAdmins = adminsData.adminList || [DEFAULT_ADMIN];
      
      // Remove the admin
      const updatedAdmins = currentAdmins.filter(email => email !== adminToRemoveEmail);
      await updateDoc(adminsDoc, { adminList: updatedAdmins });
      
      return { success: true, message: 'Admin removed successfully' };
    }
    
    return { success: false, message: 'Admin not found' };
  } catch (error) {
    console.error("Error removing admin:", error);
    throw error;
  }
};
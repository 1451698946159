// src/notificationService.js
import { getFirestore, collection, addDoc, onSnapshot, query, orderBy, limit, serverTimestamp } from 'firebase/firestore';

// Add a new notification that all users will see
export const addGlobalNotification = async (message, actionUser) => {
  const firestore = getFirestore();
  const notificationsRef = collection(firestore, 'notifications');
  
  try {
    await addDoc(notificationsRef, {
      message,
      actionUser,
      timestamp: serverTimestamp(),
      isGlobal: true
    });
    
    return true;
  } catch (error) {
    console.error("Error adding notification:", error);
    return false;
  }
};

// Subscribe to global notifications
export const subscribeToNotifications = (callback) => {
  const firestore = getFirestore();
  const notificationsRef = collection(firestore, 'notifications');
  
  // Query for the most recent 10 notifications, ordered by timestamp
  const q = query(
    notificationsRef,
    orderBy('timestamp', 'desc'),
    limit(10)
  );
  
  // Set up the real-time listener
  const unsubscribe = onSnapshot(q, (snapshot) => {
    // Get notification data and include id
    
    // Filter for new notifications (added in this snapshot)
    const newNotifications = snapshot.docChanges()
      .filter(change => change.type === 'added')
      .map(change => ({
        id: change.doc.id,
        ...change.doc.data()
      }))
      // Sort by timestamp (newest first)
      .sort((a, b) => {
        if (!a.timestamp || !b.timestamp) return 0;
        return b.timestamp.seconds - a.timestamp.seconds;
      });
      
    // Only call back with new notifications that have non-null timestamps
    // This prevents showing notifications when first loading the page
    if (newNotifications.length > 0 && newNotifications[0].timestamp) {
      callback(newNotifications[0]);
    }
  });
  
  // Return the unsubscribe function
  return unsubscribe;
};